import React, {useEffect, useRef} from "react";
import ReactDOM from 'react-dom';
import './modalComponent.css';


function ModalComponent({isOpen, children, onClose, isBackgroundBlur, isCloseButtonHidden, customClass}) {
    const modalRef = useRef();

    useEffect(() => {
        const app = document.getElementById('root');

        if (isOpen) {
            app.style.overflow = 'hidden';
            app.style.position = 'fixed';
            app.style.width = '100%';
            // Переносим фокус на модальное окно
            if (modalRef.current) {
                modalRef.current.focus();
            }
        } else {
            app.style.overflow = '';
            app.style.position = 'initial';
            app.style.width = 'initial';
        }
        return () => {
            app.style.overflow = '';
            app.style.position = 'initial';
            app.style.width = 'initial';
        };
    }, [isOpen]);

    useEffect(() => {
        const handleEsc = event => {
            if (event.key === 'Escape' && isOpen) {
                onClose();
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    let modalOverlayClass = ["modal-overlay"]
    if (customClass) modalOverlayClass.push(customClass)

    return ReactDOM.createPortal(
        <div
            className={modalOverlayClass.join(' ')}
            onClick={onClose}
            role="presentation"
        >
            <div aria-hidden="true" className={isBackgroundBlur ? "modal-backdrop-blur" : "modal-backdrop"}
                 onClick={onClose}/>
            <div
                className="modal-content"
                onClick={onClose}
                role="dialog"
                aria-modal="true"
                ref={modalRef}
                tabIndex={-1}
            >
                <div className='modal-content-entry' onClick={e => e.stopPropagation()}>
                    {
                        isCloseButtonHidden ? null :
                            <button className="modal-close" onClick={onClose} aria-label="Закрыть модальное окно">
                                ×
                            </button>
                    }
                    {children}
                </div>
            </div>
        </div>,
        document.body
    );
}


export default ModalComponent;
