import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {updateCategories, updateMainPage, updateNavigationParam, updateUser} from "../../redux/systemSlice";
import {connect} from "react-redux";

import './business.css';
import Utils from "../../utils";

import {ReactComponent as BusinessLogo} from "../../images/business-logo.svg";
import {ReactComponent as BusinessPhone} from "../../images/business-phone.svg";
import {ReactComponent as BusinessCross} from "../../images/business-cross.svg";
import {ReactComponent as BusinessTrafficLogo} from "../../images/business-traffic-logo.svg";
import {ReactComponent as BusinessBeer} from "../../images/business-beer.svg";
import {ReactComponent as BusinessPicture} from "../../images/business-picture.svg";
import {ReactComponent as BusinessPeople} from "../../images/business-people.svg";
import {ReactComponent as BusinessSale} from "../../images/business-sale.svg";
import {ReactComponent as BusinessArrow} from "../../images/business-arrow.svg";
import {Link} from "react-router-dom";
import ModalComponent from "../../app/components/modalComponent/modalComponent";
import API from "../../app/api";


const tags = [
    {
        text: 'Интерактивные меню-борды',
        deg: (Math.random() * 10) - 5
    },
    {
        text: 'Ремонт',
        deg: (Math.random() * 10) - 5
    },
    {
        icon: <BusinessBeer/>,
        deg: (Math.random() * 10) - 5
    },
    {
        text: 'Наружная реклама',
        deg: (Math.random() * 10) - 5
    },
    {
        text: 'Дизайн-проект',
        deg: (Math.random() * 10) - 5
    },
    {
        text: 'Холодильные камеры',
        deg: (Math.random() * 10) - 5
    },
    {
        icon: <BusinessPicture/>,
        deg: (Math.random() * 10) - 5
    },
    {
        text: 'Сплит-системы',
        deg: (Math.random() * 10) - 5
    },
    {
        text: 'Барные стойки, мебель',
        deg: (Math.random() * 10) - 5
    },
    {
        text: 'Система ЕГАИС',
        deg: (Math.random() * 10) - 5,
    },
    {
        text: 'Настройка кассы',
        deg: (Math.random() * 10) - 5
    },
    {
        icon: <BusinessPeople/>,
        deg: (Math.random() * 10) - 5
    },
    {
        text: 'Сдача алкодекларации',
        deg: (Math.random() * 10) - 5
    },
    {
        text: 'Линия розлива, подключение кранов',
        deg: (Math.random() * 10) - 5
    },
    {
        icon: <BusinessSale/>,
        deg: (Math.random() * 10) - 5
    },
]


const questions = [
    {
        title: 'Можно ли заказать только ремонт?',
        description: 'Да, причем, тариф позволяет разбить ремонт по зонам и заказать нужную: сантехника, ' +
            'электрика, стены, потолок, пол и т.п.'
    },
    {
        title: 'Вы ставите новое оборудование или БУ?',
        description: 'Мы предоставляем, как камеры с гарантией, так и уцененные. ' +
            'Рассматриваем варианты сделать самосборную, при этом сплит-система или моноблок — тоже решать ' +
            'вам — а мы поможем в любом случае.'
    },
    {
        title: 'Как мы построим совместную работу?',
        description: 'На первой встрече составим план, согласуем каждую мелочь. Затем приедем, ' +
            'проконтролируем и примем все строительные работы. Привезём, установим и соберём все оборудование. ' +
            'Сделаем финишную подготовку, установим меню и кассы. Заключим договора с поставщиками, ' +
            'включающие наши скидки. Пригласим вас и введём в курс — закупаем товары и готово, можно работать.'
    },
    {
        title: 'Если у меня возникнут вопросы, могу ли я обратиться к вам за помощью?',
        description: 'Конечно, мы сопровождаем вас еще в течение месяца. Чтобы вы смогли комфортно ' +
            'запустить магазин, мы приедем и поработаем с вами 2 дня'
    },
    {
        title: 'Какие ещё услуги у вас есть?',
        description: 'У нас огромная аутсорс-команда: бухгалтеры, юристы, маркетологи, SMM-специалисты, ' +
            'дизайнеры.\n\nПоможем со всем, что можно и нельзя придумать.'
    },
]


class BusinessTraffic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
            deg: 0,

            accordionTabActive: 0,

            isModalOpen: false,

            name: '',
            phone: '',

            isSuccess: false,
        }

        this.setModalStatus = (status) => this.setState({isModalOpen: status})

        this.setAccordionTab = (index) => {
            if (this.state.accordionTabActive === index) {
                this.setState({accordionTabActive: null})

                questions.forEach((question, thisIndex) => {
                    const container = document.getElementById(`question-${thisIndex}`);
                    container.style.height = '0px';
                })
            } else {
                this.setState({accordionTabActive: index})

                questions.forEach((question, thisIndex) => {
                    if (thisIndex === index) {
                        const container = document.getElementById(`question-${thisIndex}`);
                        container.style.height = container.scrollHeight * 1.6 + (16 * 2) + 'px';
                    } else {
                        const container = document.getElementById(`question-${thisIndex}`);
                        container.style.height = '0px';
                    }
                })
            }
        }

        this.scrollToSalary = () => {
            const element = document.getElementById('salary');
            const offset = 140;

            const elementPosition = element.getBoundingClientRect().top;

            const offsetPosition = window.pageYOffset + elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }

        this.sendBusinessRequest = () => {
            if (this.state.name.length && this.state.phone.length) {
                API.sendBusinessLandingRequest(this.state.name, this.state.phone)
                    .then(data => {
                        this.setState({
                            name: '',
                            phone: '',
                            isSuccess: true
                        })
                        setTimeout(() => {
                            this.setState({
                                isModalOpen: false,
                                isSuccess: false
                            })
                        }, 3000)
                    })
            }
        }
    }

    componentDidMount() {
        this.props.updateNavigationParam({
            page: 'business',
        })

        Utils.setPageTitle(`Готовый бизнес под ключ: пивные магазины, кофейни | ПабХаб`)

        setInterval(() => {
            this.setState({
                activeTab: this.state.activeTab + 1 === tags.length ? 0 : this.state.activeTab + 1,
                deg: (Math.random() * 10) - 5
            })
        }, 800)
    }

    render() {
        return (
            <div className='business-container'>
                <div className='header'>
                    <Link className='logo-container' to='/'>
                        <BusinessLogo/>
                        <div className='text'>pubhub</div>
                        <BusinessCross className='cross'/>
                        <BusinessTrafficLogo/>
                        <div className='text'>traffic</div>
                    </Link>
                    <div className='buttons'>
                        <a className='button gray' href='tel:+79505071316'>
                            <div className='span'>Пишите, звоните</div>
                            <div className='phone'>
                                <BusinessPhone/>
                                8 950 507 13 16
                            </div>
                        </a>
                        <div className='button request' onClick={() => this.setModalStatus(true)}>
                            Оставить заявку
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <div className='entry'>
                        <div className='block-1'>
                            <div className='left'>
                                <div className='title'>
                                    Готовый бизнес под ключ: пивные магазины, кофейни
                                </div>
                                <div className='description'>
                                    Mы не проcто стpoим мaгaзины — мы зaпускаем ваш бизнec и cопровождaем егo в тeчeниe
                                    пepвого месяца!
                                </div>
                                <div className='buttons'>
                                    <div className='button main' onClick={() => this.setModalStatus(true)}>
                                        Оставить заявку
                                    </div>
                                    <div className='button' onClick={this.scrollToSalary}>
                                        Сколько я заработаю?
                                    </div>
                                </div>
                            </div>
                            <div className='right'>
                                <img src='https://media.pubhub.su/images/portal/other/traffic-1.jpg' alt=''/>
                            </div>
                        </div>
                        <div className='block-2'>
                            <div className='top'>
                                <div className='title'>
                                    Что можно открыть с нашей помощью?
                                </div>
                                <div className='description'>
                                    Любой формат заведения на ваш вкус. Если хотите — поможем с выбором.
                                </div>
                            </div>
                            <div className='bottom'>
                                <div className='card'>
                                    <img src='https://media.pubhub.su/images/portal/other/traffic-2.jpg' alt=''/>
                                    <div className='text'>
                                        Пивной магазин
                                    </div>
                                </div>
                                <div className='card'>
                                    <img src='https://media.pubhub.su/images/portal/other/traffic-3.jpg' alt=''/>
                                    <div className='text'>
                                        Табачная лавка
                                    </div>
                                </div>
                                <div className='card'>
                                    <img src='https://media.pubhub.su/images/portal/other/traffic-4.jpg' alt=''/>
                                    <div className='text'>
                                        Кофейня
                                    </div>
                                </div>
                                <div className='card'>
                                    <img src='https://media.pubhub.su/images/portal/other/traffic-5.jpg' alt=''/>
                                    <div className='text'>
                                        Раковарня
                                        <span>new</span>
                                    </div>
                                </div>
                                <div className='last card'>
                                    <div className='description'>
                                        или закрыть все потребности посетителей
                                    </div>
                                    <div className='title'>
                                        и совместить всё в одном заведении
                                    </div>
                                </div>
                            </div>
                            <div className='bottom-super'>
                                <div className='label'>
                                    Также можем обсудить возможность открытия бара и любые другие гибридные форматы
                                </div>
                            </div>
                        </div>
                        <div className='block-3'>
                            <div className='title'>Что мы можем?</div>
                            <div className='tags'>
                                {
                                    tags.map((tag, index) => {
                                        if (tag.icon) return <div key={index}
                                                                  className={`tag ${this.state.activeTab === index ? 'hover' : ''}`}
                                                                  style={{'--rotate-deg': `rotate(${this.state.deg}deg) scale(1.15)`}}>
                                            {tag.icon}
                                        </div>
                                        return <div key={index}
                                                    className={`tag ${this.state.activeTab === index ? 'hover' : ''}`}
                                                    style={{'--rotate-deg': `rotate(${this.state.deg}deg) scale(1.15)`}}>
                                            {tag.text}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className='block-4'>
                            <div className='title'>Почему стоит выбрать нас?</div>
                            <div className='cards-top'>
                                <div className='card'>
                                    Самые низкие цены на ремонт за м2 у наших подрядчиков
                                </div>
                                <div className='card'>
                                    Команда профессионалов по сбору пивного оборудования
                                </div>
                                <div className='card'>
                                    Оптовые цены на пивное оборудование — краны, камеры, сплит-системы
                                </div>
                                <div className='card'>
                                    Дизайнерское сопровождение под ключ — наружная реклама, брендинг, нейминг
                                </div>
                            </div>
                            <div className='cards-bottom'>
                                <div className='card'>
                                    Сопровождение бизнеса в течение месяца. Выезд на закупку, обучение, и комплектовку
                                    магазина
                                </div>
                                <div className='card'>
                                    Индивидуальная сборка мебели — стойки, стеллажи, витрины
                                </div>
                                <div className='card'>
                                    Подключаем удобное интерактивное меню с большой базой пива
                                </div>
                            </div>
                        </div>
                        <div id='salary' className='block-5'>
                            <div className='left'>
                                <div className='title'>
                                    Сколько вы заработаете?
                                </div>
                                <div className='description'>
                                    Мы собрали статистику по среднегодовой ежемесячной прибыли у работающих с нами
                                    партнёров, посмотрите
                                </div>
                                <div className='button' onClick={() => this.setModalStatus(true)}>
                                    Мне нравится, делаем
                                </div>
                            </div>
                            <div className='right'>
                                <div className='card min'>
                                    <div className='description'>Минимальная</div>
                                    <div className='circle'></div>
                                    <div className='price'>150 000</div>
                                    <div className='delay'>₽ / мес</div>
                                </div>
                                <div className='card middle'>
                                    <div className='description'>Средняя</div>
                                    <div className='circle'></div>
                                    <div className='price'>400 000</div>
                                    <div className='delay'>₽ / мес</div>
                                </div>
                            </div>
                        </div>
                        <div className='block-6'>
                            <div className='title'>Тарифы</div>
                            <div className='card'>
                                <div className='description'>пока тариф единый, всё считаем индивидуально</div>
                                <div className='price-container'>
                                    <div className='price'>от 300 000 ₽</div>
                                    <div className='per'>единоразово</div>
                                </div>
                            </div>
                        </div>
                        <div className='block-7'>
                            <div className='columns'>
                                <div className='column'>
                                    <div className='title'>Вопросы и ответы</div>
                                    <div className='questions'>
                                        {questions.map((question, index) => {
                                            const classList = ['question']

                                            if (this.state.accordionTabActive === index) classList.push('active')

                                            return <div key={index} className={classList.join(' ')}
                                                        onClick={() => this.setAccordionTab(index)}>
                                                <div className='head'>
                                                    <div className='title'>{question.title}</div>
                                                    <div className='arrow'>
                                                        <BusinessArrow/>
                                                    </div>
                                                </div>
                                                <div id={`question-${index}`} className='description'>
                                                    {question.description}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                <div className='column'>
                                    <div className='card'>
                                        <div className='title'>Контакты</div>
                                        <div className='field'>
                                            <a href='tel:+79505071316'>8 950 507 13 16</a>
                                            <div className='label'>основной телефон</div>
                                        </div>
                                        <div className='field'>
                                            <a href='mailto:hello@pubhub.su'>hello@pubhub.su</a>
                                            <div className='label'>почта</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='block-8'>
                            <div className='label'>открываем магазины с 2018 года</div>
                        </div>
                    </div>
                </div>
                <ModalComponent isOpen={this.state.isModalOpen} customClass='business-modal-overlay'
                                onClose={() => this.setModalStatus(false)}>
                    <div className='business-modal'>
                        {
                            this.state.isSuccess ? <>
                                <div className='title'>Заявка успешно отправлена</div>
                                <div className='description'>Позвоним в течение дня</div>
                            </> : <>
                                <div className='title'>Как с вами связаться?</div>
                                <div className='description'>Позвоним в течение дня</div>
                                <div className='inputs'>
                                    <span>Ваше имя</span>
                                    <div className='input'>
                                        <input placeholder='Например, Иван' type='text' value={this.state.name}
                                               onChange={(e) => this.setState({name: e.target.value})}/>
                                    </div>
                                    <span>Телефон</span>
                                    <div className='input'>
                                        <input placeholder='Например +7 000 000 00 00' type='text'
                                               value={this.state.phone}
                                               onChange={(e) => this.setState({phone: e.target.value})}/>
                                    </div>
                                </div>
                                <div className='button' onClick={this.sendBusinessRequest}>
                                    Отправить
                                </div>
                            </>
                        }
                    </div>
                </ModalComponent>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.system.user,
        mainPage: state.system.mainPage,
        categories: state.system.categories,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateCategories: updateCategories,
            updateUser: updateUser,
            updateMainPage: updateMainPage,
            updateNavigationParam: updateNavigationParam,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTraffic);
