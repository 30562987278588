import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from "react-router-dom";
import {updateNavigation} from "../../../redux/systemSlice";

import './navigation.css';

import {ReactComponent as LogoIcon} from '../../../images/logo.svg';
import {ReactComponent as CatalogIcon} from '../../../images/catalog.svg';
import {ReactComponent as FavoriteIcon} from '../../../images/favorite.svg';
import {ReactComponent as SearchIcon} from '../../../images/search.svg';
import {ReactComponent as BackIcon} from '../../../images/back.svg';
import {withRouterHOC} from "../../../utils";
import FadeComponent from "../animations/animations";


const pages = [
    {
        icon: <LogoIcon/>,
        path: '/',
        page: 'home',
    },
    {
        icon: <CatalogIcon/>,
        path: '/catalog',
        page: 'catalog',
    },
    {
        icon: <FavoriteIcon/>,
        path: '/favourite',
        page: 'favourite',
    },
    {
        icon: <SearchIcon/>,
        path: '/search',
        page: 'search',
    },
]

const hiddenPages = [
    {
        path: '/business/',
        page: 'business',
    },
]

// const pageByPath = pages.reduce((a, v) => ({...a, [v.path]: v}), {})


class NavigationComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        let isHidden = false
        hiddenPages.forEach((page, index) => {
            if (this.props.navigation.page === page.page) {
                isHidden = true
            }
        })

        if (isHidden) return

        const isBackActive = this.props.navigation.catalogType !== undefined || this.props.navigation.page === 'product'

        return (
            <div className='navigation-container'>
                {
                    <FadeComponent isVisible={isBackActive}>
                        <div className='navigation navigation-back-button-container'>
                            <div className="navigation-button" onClick={() => this.props.navigate(-1)}>
                                <BackIcon/>
                            </div>
                        </div>
                    </FadeComponent>
                }
                <FadeComponent isVisible={true}>
                    <div className='navigation'>
                        {
                            pages.map((page, index) => {
                                let pageClass = ['navigation-button']

                                if (this.props.navigation.page === page.page) {
                                    pageClass.push('active')
                                }

                                return <Link key={index} className={pageClass.join(' ')} to={page.path}>
                                    {page.icon}
                                </Link>
                            })
                        }
                    </div>
                </FadeComponent>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        navigation: state.system.navigation,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateNavigation: updateNavigation,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouterHOC(NavigationComponent));