import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages/home/home';
import NavigationComponent from "./app/components/navigation/navigation";
import Catalog from "./pages/catalog/catalog";
import CatalogEntity from "./pages/catalog/catalogCategory";
import CatalogCollection from "./pages/catalog/catalogCollection";
import Product from "./pages/product/product";
import HeaderComponent from "./app/components/header/header";
import Favourite from "./pages/favourite/favourite";
import CatalogBreweryDetail from "./pages/catalog/catalogBreweryDetail";
import CatalogBreweryProducts from "./pages/catalog/catalogBreweryProducts";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import CatalogShopDetail from "./pages/catalog/catalogShopDetail";
import CatalogShopProducts from "./pages/catalog/catalogShopProducts";
import API from "./app/api";
import Storage from "./utils/storage";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {updateMainPage, updatePartnerUser, updateUser} from "./redux/systemSlice";
import {withCookies} from "react-cookie";
import Forgot from "./pages/auth/forgot";
import ForgotRecoveryCode from "./pages/auth/forgotRecoveryCode";
import UserProfile from "./pages/user/user";
import Search from "./pages/search/search";
import AgreeAccept from "./app/components/agreeAccept/agreeAccept";
import BusinessTraffic from "./pages/business/business";


class App extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let seo = document.getElementById('seo');
        if (seo) seo.remove()

        Storage.set('portalToken', this.props.cookies.get('portalToken'))
        Storage.set('token', this.props.cookies.get('token'))

        API.profile()
            .then(data => {
                Storage.set('portalUser', data)
                data.authorize = true;
                this.props.updateUser(data)
            })
            .catch(e => {
                this.props.updateUser({authorize: false})
            })
        API.profilePartner()
            .then(data => {
                Storage.set('user', data)
                data.authorize = true;
                this.props.updatePartnerUser(data)
            })
            .catch(e => {
                this.props.updatePartnerUser({authorize: false})
            })
    }

    render() {
        return (
            <Router>
                <HeaderComponent/>
                <AgreeAccept/>
                <Routes>
                    <Route
                        path="/"
                        element={<Home API={API}/>}
                    />
                    <Route
                        path="/login/"
                        element={<Login/>}
                    />
                    <Route
                        path="/register/"
                        element={<Register/>}
                    />
                    <Route
                        path='/forgot/'
                        element={<Forgot/>}
                    />
                    <Route
                        path='/forgot/recovery/code/'
                        element={<ForgotRecoveryCode/>}
                    />
                    <Route
                        path="/search/"
                        element={<Search/>}
                    />
                    <Route
                        path="/catalog/"
                        element={<Catalog/>}
                    />
                    <Route
                        path="/catalog/:catalogType/"
                        element={<CatalogEntity/>}
                    />
                    <Route
                        path="/catalog/brewery/:catalogSlug/"
                        element={<CatalogBreweryProducts/>}
                    />
                    <Route
                        path="/catalog/brewery/:catalogSlug/detail/"
                        element={<CatalogBreweryDetail/>}
                    />
                    <Route
                        path="/shop/:shopSlug/"
                        element={<CatalogShopDetail/>}
                    />
                    <Route
                        path="/shop/:shopSlug/menu/"
                        element={<CatalogShopProducts/>}
                    />
                    <Route
                        path="/catalog/:catalogType/:catalogSlug/"
                        element={<CatalogCollection/>}
                    />
                    <Route
                        path="/product/:productSlug/"
                        element={<Product/>}
                    />
                    <Route
                        path="/user/:userSlug/"
                        element={<UserProfile/>}
                    />
                    <Route
                        path="/favourite/"
                        element={<Favourite/>}
                    />
                    <Route
                        path="/business/"
                        element={<BusinessTraffic/>}
                    />
                </Routes>
                <NavigationComponent/>
            </Router>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: state.system.user,
    partnerUser: state.system.partnerUser
})

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(
        {
            updateUser: updateUser,
            updateMainPage: updateMainPage,
            updatePartnerUser: updatePartnerUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(App));